<template>
  <b-container class="page-container profile-selector">
    <h1>Selecteer een profiel om verder te gaan</h1>
    <b-alert show variant="danger"
      ><font-awesome-icon icon="exclamation-circle" /> Uw account is gekoppeld
      aan meerdere profielen.
    </b-alert>
    <b-row class="pt-4">
      <b-col
        v-for="account in accounts"
        :key="account.ContactID"
        class="card-userswitch mt-4 mt-lg-0"
        xs="12"
        sm="12"
        md="12"
        lg="6"
        xl="4"
      >
        <b-card class="mb-4"
          ><p>
            {{ account.CompanyName }}
            <small>{{ account.FullNameWOSalutation }} </small>
          </p>
          <p></p>
          <b-button
            variant="primary"
            :value="account.ContactID"
            :disabled="!account.IsCustomerActive"
            @click="selectContactID"
            >Verder gaan als {{ account.CompanyName }}</b-button
          ></b-card
        >
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { BButton, BCard, BAlert, BContainer, BCol, BRow } from 'bootstrap-vue'
export default {
  components: { BButton, BCard, BAlert, BContainer, BCol, BRow },
  data() {
    return {}
  },
  computed: {
    accounts: function() {
      return this.$store.state.accounts
    }
  },
  methods: {
    async selectContactID(event) {
      await this.$store.commit('setContactID', {
        contactID: event.target.value
      })
      this.$router.push({ name: 'account', params: { loggedIn: true } })
    }
  }
}
</script>
<style lang="scss">
.profile-selector {
  .btn {
    border-radius: 0px !important;
  }
}
</style>
